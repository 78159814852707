<template>
  <el-dialog class="dialog2" :visible.sync="dialogVisibletwo" :width="screenWidth>500?'450px':'344px'">
    <div class="top" slot="title">
       <div>{{$t('erweimaDialog.汇生汇')}} <span>({{$t('erweimaDialog.香港')}}) {{$t('erweimaDialog.文化科技有限公司')}}</span></div>
      <div class="text">({{$t('erweimaDialog.深圳')}}) {{$t('erweimaDialog.文化科技有限公司')}}</div>
      <div class="text">{{$store.state.info.hotline}}</div>
      <div class="text">{{$store.state.info.phone}}</div>
    </div>
    <div class="content3">
      <img :src="baseUrl+$store.state.info.wxServiceUrl" alt=""/>
      <div>{{$t('erweimaDialog.联系我们')}}</div>
      <div>{{$t('erweimaDialog.了解更多')}}</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "erweima",
  data() {
    return {
      dialogVisibletwo: false,
      form: {},
      customerewm:{},
      baseUrl:'',
      screenWidth: document.body.clientWidth,
    };
  },
  created(){
    this.baseUrl=this.$store.state.baseUrl
    
  },
  methods: {
    open() {
      this.dialogVisibletwo = true;
    },
  },
};
</script>

<style lang="scss">
.dialog2 {
  .el-dialog {
    border-radius: 12PX;
  }

  .el-dialog__header {
    // text-align: left;
    background-color: #fe992b;
    border-radius: 12PX 12PX 0 0;
  }

  .el-dialog__close {
    color: #fff;
    font-size: 20PX;
  }
 .el-dialog__close::before {
    color: #fff;
    font-size: 20PX;
  }
  .content3 {
    font-size: 14PX;
    div {
      margin-bottom: 5PX;
      margin-top: 5PX;
    }

    img {
      width: 183PX;
      height: 183PX;
    }
  }

  .top {
    text-align: left;
    color: #fff;
    font-size: 14PX;

    .text {
      margin-left: 56PX;
    }

    > div {
      span {
        margin-left: 10PX;
      }

      margin-bottom: 4PX;
    }
  }
}
</style>